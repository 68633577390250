<template>
  <div class="center">
    <button :id="row.id" @click = "impersonate" class = "ml-2 btn btn-default" >
      Login <i class="fa fa-sign-in"></i>
    </button>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],

  methods : {
    impersonate() {
      sessionStorage.setItem("businessId", this.row.id);

      this.$store.state.app.isLoading = true;
      this.$store.dispatch("auth/impersonate", this.row.user.id).then(success => {
        this.$store.state.app.isLoading = false;
      }, error => {
        this.$store.state.app.isLoading = false;
      }).catch(error => {
        console.log('Error', error);
        this.$store.state.app.isLoading = false;
      });
    },
  }
}
</script>
<style scoped>
  .btn {
    color: #3578c6
  }
</style>

<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">Business Accounts
          <span class="inst_av">
            ( {{ availableInstalls }} {{ availableInstalls > 1 ? 'installs' : 'install' }} available out of
            {{ totalInstalls }} )
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div>
            <b-nav pills>
              <b-nav-item active><router-link :to="{ name: 'businessAccountsActive' }">Active</router-link></b-nav-item>
              <b-nav-item><router-link :to="{ name: 'businessAccountsArchived' }">Archived</router-link></b-nav-item>
            </b-nav>
          </div>
          <div class="searchForm pl-2">
            <text-input name="search" v-model="search" placeholder="Search..." style="width: 250px"></text-input>
            <button class="btn btn-default btn-primary btn-sm ml-2" @click="handleSearch()" :disabled="!search">
              <i class="fa fa-search"></i> Filter
            </button>
            <button class="btn btn-default btn-primary btn-sm ml-2" @click="clearSearch()">
              Reset
            </button>
          </div>
        </div>
      </div>
    </template>
    <div v-if="twilioStatus === 'Trial' && !isTemplate" class="alert alert-danger justify--center"
      style="box-shadow:none;">
      Your twilio account is trial type, Please use paid account to avoid limitations
    </div>

    <div v-if="false" class="alert alert-primary alert-dismissible fade show d-flex align-items-start agency-notification"
      style="box-shadow:none;">
      <span class="d-block"><img class="logo" src="/favicon.ico" width="30" alt="logo" /></span>
      <div class="pl-3">
        <span class="d-block">Welcome to CallWidget!</span>
        <span class="d-block">Join our community to engage with other customers and check out FAQs.</span>
        <div class="d-flex mt-1">
          <button class="btn btn-primary btn-sm mr-2">Register for training workshops</button>
          <button class="btn btn-primary btn-sm">Register for Accelerated Onboarding</button>
        </div>
      </div>
    </div>
    <div class="filter-wrapper pb-2 mb-3">
      <div class="d-flex align-items-center">
      </div>
      <div>
        <div class="ml-2">
          <button class="btn btn-sm btn-primary mr-2" @click="onExportUsers">
            <span><i class="fa fa-download"></i> Export Users</span>
          </button>
          <button class="btn btn-sm btn-primary mr-2" @click="onExportBusinesses">
            <span><i class="fa fa-download"></i> Export Businesses</span>
          </button>
          <button class="btn btn-sm btn-primary mr-2" @click="onSendNotification">
            <span><i class="fa fa-send"></i> Send Notification</span>
          </button>
        </div>
      </div>
    </div>
    <span class="alert-span">In order to edit a widget you must click on the login button for that business.</span>
    <datatable v-bind="$data" class="le-datatable" />

    <vuestic-modal :isOpen="isOpenModalDelete" @ok="handleDeleteBusiness" @cancel="closeModalDelete" okText="Delete"
      cancelText="Cancel" okClass="btn btn-danger" :closeOnOk="false" :processing="processing">
      <span slot="title" class="text-danger font-weight-bold">Delete Business</span>

      <div>Are you sure you want to delete the business for {{ modalDeleteBusiness.name }}?</div>

      <div>The business will be archived for 15 days and then permanently deleted.</div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalSend" @ok="handleSendPassword" @cancel="closeSendPasswordModal" okText="Send"
      cancelText="Cancel">
      <span slot="title" class="text-primary font-weight-bold">Sent New Password</span>

      <div>Are you sure you want to send new password to the business <b>{{ modalSendPassword.name }}</b> ?</div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalResetUsage" @ok="handleResetUsage" @cancel="closeResetUsageModal" okText="Reset"
      cancelText="Cancel">
      <span slot="title" class="text-primary font-weight-bold">Reset Usage</span>

      <div>Are you sure you want to reset usage for the business <b>{{ modalResetUsage.name }}</b> ?</div>
      <div class="mt-2">
        <p class="mt-2 mb-1"><b>Current Usage</b><br/></p>
        <p class="my-0">Calls: <span :class="{'text-danger font-weight-bold': modalResetUsage.calls > modalResetUsage.number_of_calls}">{{modalResetUsage.calls}}</span> / {{modalResetUsage.number_of_calls}}</p>
        <p class="my-0">Messages: <span :class="{'text-danger': modalResetUsage.messages > modalResetUsage.number_of_messages}">{{modalResetUsage.messages}}</span> / {{modalResetUsage.number_of_messages}}</p>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenCloneModal" @close="closeCloneModal" :cancelShown="false" :okShown="false" large>
      <span slot="title" class="text-primary font-weight-bold">Clone Business</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="businessForm">
        <form @submit.prevent="handleSubmit(handleCloneModal)">
          <div class="row">
            <div class="col-md-6">
              <text-input name="Full Name" v-model="newBusiness.fullName" label="Full Name" validate="required" />
            </div>

            <div class="col-md-6">
              <text-input validate="required" name="CompanyName" v-model="newBusiness.companyName" label="Company Name" />
            </div>

            <div class="col-md-6 col-sm-12">
              <text-input validate="required|email" name="email" v-model="newBusiness.email" label="Registration Email"
                @focusout.native="uniqEmailChk()" v-bind="{ type: 'email' }" />
            </div>

            <div class="col-md-6">
              <div class="control-label mt-2">Send Registration Email</div>
              <vuestic-switch v-model="newBusiness.sendEmail" :offcolor=true>
                <span slot="trueTitle">ON</span>
                <span slot="falseTitle">OFF</span>
              </vuestic-switch>
            </div>
            <div class="col-md-12">
              <phone-number-input name="DefaultBusinessPhoneNumber" v-model="newBusiness.phone"
                label="Default Business Number" />
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="btn btn-primary" :disabled="invalid || isLoadingClone">
              <atom-spinner v-if="isLoadingClone" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
              <span v-else>Clone</span>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenPackageModal" @ok="handleAddPackage" @cancel="isOpenPackageModal = false" okText="Add"
      cancelText="Cancel" :closeOnOk="false" :processing="processing" :okDisabled="!selectedPackage"
      okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Add Package</span>
      <div class="row">
        <div class="col-md-12">
          <label class="lelabel">Select a Package below: </label>
          <multiselect v-model="selectedPackage" :options="packages" :multiple="false" :close-on-select="true"
            :clear-on-select="false" :preserve-search="true" placeholder="Select a Package" label="name" track-by="id"
            :preselect-first="true" :show-labels="false">
          </multiselect>
        </div>
        <div v-if="selectedPackage" class="col-md-12 mt-4 modal-detail">
          <ul>
            <li><span class="key">Price:</span> <span v-if="selectedPackage.is_free"> Free</span><span v-else> ${{
              selectedPackage.price }}</span></li>
            <li><span class="key">SMS Credit:</span> {{ selectedPackage.sms_credit }}</li>
            <li><span class="key">Call Credit:</span> {{ selectedPackage.voice_credit }}</li>
          </ul>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenDetailModal" @ok="isOpenDetailModal = false" @cancel="isOpenDetailModal = false"
      okText="Ok" :cancelShown="false" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Business Detail</span>
      <div v-if="selectedBusiness" class="row modal-detail business-detail">
        <div class="col-md-12">
          <ul>
            <li><span class="key">Name:</span> {{ selectedBusiness.name }}</li>
            <li><span class="key">Phone:</span> {{ selectedBusiness.phone }}</li>
            <li><span class="key">SMS Credit:</span> {{ selectedBusiness.number_of_messages_org }}</li>
            <li v-if="selectedBusiness.additional_messages"><span class="key">SMS Credit (Extra):</span> {{
              selectedBusiness.additional_messages }}</li>
            <li><span class="key">Call Credit:</span> {{ selectedBusiness.number_of_calls_org }}</li>
            <li v-if="selectedBusiness.additional_calls"><span class="key">Call Credit (Extra):</span> {{
              selectedBusiness.additional_calls }}</li>
            <li><span class="key">Number of Intalls:</span> {{ selectedBusiness.number_of_installs }}</li>
            <li><span class="key">Number of Users:</span> {{ selectedBusiness.number_of_users }}</li>
          </ul>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenExportBusinessModal" @ok="handleExportBusinesses" @cancel="isOpenExportBusinessModal = false"
      okText="Export" cancelText="Cancel" okClass="btn btn-primary" :closeOnOk="false" :processing="processing">
      <span slot="title" class="text-primary font-weight-bold">Export Businesses</span>

      <div class="row">
        <div class="col-md-12 mb-4">
          <div class="control-label">Businesses
            <a v-b-tooltip.hover title="Leave it blank and it will be applied to all businesses"
              class="fa fa-question-circle tooltip-icon" />
          </div>
          <multiselect :show-labels="false" v-model="businessExportOptions.businessSelected" :options="businesses"
            :multiple="true" :preserve-search="true" placeholder="Select Businesses" label="name" track-by="id">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                v-if="businessExportOptions.businessSelected && businessExportOptions.businessSelected.length & !isOpen">{{
                  businessExportOptions.businessSelected.length }} options
                selected</span></template>
          </multiselect>
          <ul v-if="businessExportOptions.businessSelected" class="selectedBusinessWrapper">
            <li v-for="item in businessExportOptions.businessSelected" :key="item.id">{{ item.name }}</li>
          </ul>
        </div>
      </div>
    </vuestic-modal>
    
    <vuestic-modal :isOpen="isOpenExportUserModal" @ok="handleExportUsers" @cancel="isOpenExportUserModal = false"
      okText="Export" cancelText="Cancel" okClass="btn btn-primary" :closeOnOk="false" :processing="processing">
      <span slot="title" class="text-primary font-weight-bold">Export Users</span>

      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="userExportOptions.agencyUsers">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2" style="cursor: pointer"
            @click="userExportOptions.agencyUsers = !userExportOptions.agencyUsers">Agency Users</span>
        </div>
        <div class="col-md-12 mb-3">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="userExportOptions.businessUsers">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2" style="cursor: pointer"
            @click="userExportOptions.businessUsers = !userExportOptions.businessUsers">Business Users</span>
        </div>
        <div v-if="userExportOptions.businessUsers" class="col-md-12 mb-4">
          <div class="control-label">Businesses
            <a v-b-tooltip.hover title="Leave it blank and it will be applied to all businesses"
              class="fa fa-question-circle tooltip-icon" />
          </div>
          <multiselect :show-labels="false" v-model="userExportOptions.businessSelected" :options="businesses"
            :multiple="true" :preserve-search="true" placeholder="Select Businesses" label="name" track-by="id">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                v-if="userExportOptions.businessSelected && userExportOptions.businessSelected.length & !isOpen">{{
                  userExportOptions.businessSelected.length }} options
                selected</span></template>
          </multiselect>
          <ul v-if="userExportOptions.businessSelected" class="selectedBusinessWrapper">
            <li v-for="item in userExportOptions.businessSelected" :key="item.id">{{ item.name }}</li>
          </ul>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenSendNotificationModal" @ok="handleSendNotification"
      @cancel="isOpenSendNotificationModal = false" okText="Send" cancelText="Cancel" okClass="btn btn-primary"
      :okDisabled="!canSendNotification" :closeOnOk="false" :processing="processing">
      <span slot="title" class="text-primary font-weight-bold">Send Notification</span>

      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="userNotificationOptions.agencyUsers">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2" style="cursor: pointer"
            @click="userNotificationOptions.agencyUsers = !userNotificationOptions.agencyUsers">Agency Users</span>
        </div>
        <div class="col-md-12 mb-3">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="userNotificationOptions.businessUsers">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2" style="cursor: pointer"
            @click="userNotificationOptions.businessUsers = !userNotificationOptions.businessUsers">Business Users</span>
        </div>
        <div v-if="userNotificationOptions.businessUsers" class="col-md-12">
          <div class="control-label">Businesses
            <a v-b-tooltip.hover title="Leave it blank and it will be applied to all businesses"
              class="fa fa-question-circle tooltip-icon" />
          </div>
          <multiselect :show-labels="false" v-model="userNotificationOptions.businessSelected" :options="businesses"
            :multiple="true" :preserve-search="true" placeholder="Select Businesses" label="name" track-by="id">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                v-if="userNotificationOptions.businessSelected && userNotificationOptions.businessSelected.length & !isOpen">{{
                  userNotificationOptions.businessSelected.length }} options
                selected</span></template>
          </multiselect>
          <ul v-if="userNotificationOptions.businessSelected" class="selectedBusinessWrapper">
            <li v-for="item in userNotificationOptions.businessSelected" :key="item.id">{{ item.name }}</li>
          </ul>
        </div>
        <div class="col-md-12 mt-4">
          <text-input label="Subject" v-model="userNotificationOptions.emailSubject" name="subject"></text-input>
          <div class="control-label mt-2">Content</div>
          <TextEditorPicker v-model="userNotificationOptions.emailContent" :focused="true" :rows="7" :enabledEmoji="true"
            :enabledMenu="true" :showSendButton="false" ref="texteditor" :files="userNotificationOptions.emailAttachments"
            @done="handleAttachments" :height="300"/>
        </div>
      </div>
    </vuestic-modal>

  </vuestic-widget>
</template>

<script>
import axios from 'axios'
import ActionsActive from './Actions/ActionsActive'
import MoreDropdown from './Actions/MoreDropdown'
import BusinessToggleActive from './Actions/ToggleActive'
import TextareaEmojiPicker from "../../common/TextareaEmojiPicker";
import TextEditorPicker from "../../common/TextEditorPicker";
import components from '../../common/tables/comps'

export default {
  components: {
    TextareaEmojiPicker, TextEditorPicker,
  },

  created() {
    this.xprops.eventbus.$on('openDeleteModal', row => {
      this.openModalDelete(row)
    })

    this.xprops.eventbus.$on('openSendPasswordModal', row => {
      this.openSendPasswordModal(row)
    })

    this.xprops.eventbus.$on('openResetUsageModal', row => {
      this.openResetUsageModal(row)
    })

    this.xprops.eventbus.$on('sendVerificationEmail', row => {
      this.sendVerificationEmail(row)
    })

    this.xprops.eventbus.$on('openVerifyEmail', row => {
      this.verifyEmail(row)
    })

    this.xprops.eventbus.$on('clone', row => {
      this.newBusiness = {}
      this.openCloneModal(row)
    })

    this.xprops.eventbus.$on('package', row => {
      this.selectedBusiness = row
      this.isOpenPackageModal = true
    })

    this.xprops.eventbus.$on('detail', row => {
      this.selectedBusiness = row
      this.isOpenDetailModal = true
    })
  },
  mounted() {
    this.xprops.eventbus.$on('toggleActive', this.toggleActive)
    $('.disabled-menu').removeClass('disabledTrue')
    this.loadInstallStats()
    this.getTwilioStatus()
    this.loadPackages()
    this.getAllBusinesses()
  },
  data() {
    return {
      totalInstalls: '',
      availableInstalls: '',
      loading: false,
      isOpenModalDelete: false,
      modalDeleteBusiness: {},
      isOpenModalSend: false,
      modalSendPassword: {},
      isOpenModalResetUsage: false,
      modalResetUsage: {},
      supportBackup: false,
      supportNested: false,
      HeaderSettings: false,
      tblClass: 'table-bordered',
      tblStyle: 'color: #666',
      pageSizeOptions: [5, 10, 25, 50, 100],
      isOpenCloneModal: false,
      selectedBusiness: null,
      newBusiness: {},
      isLoadingClone: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      columns: (() => {
        return [
          { title: 'ID', field: 'id', label: 'Business ID', sortable: true, visible: true, },
          { title: 'Business Name', sortable: true, field: 'name', tdStyle: { fontStyle: 'normal' }, },
          { title: 'Total Installs', field: 'count_active_installs', sortable: true, visible: true, tdClass: 'center', },
          { title: 'Active', field: 'active', sortable: false, visible: true, tdClass: 'center', tdComp: BusinessToggleActive, },
          { title: 'Verified', field: 'email_verified', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdBoolean', },
          { title: 'ResetAt', field: 'reset_at', sortable: false, visible: true, tdClass: 'center', tdComp: 'TdDay', thComp: 'ThReset' },
          { title: 'Unread Messages', field: 'unread_messages', sortable: true, visible: true, tdClass: 'center' },
          { title: 'Operation', tdComp: ActionsActive, visible: 'true', tdClass: 'center', },
          { title: '', tdComp: MoreDropdown, visible: 'true', tdClass: 'center', },
        ]
      })(),
      data: [],
      total: 0,
      // selection: [],  //if this is present the row selector shows up..
      summary: {},
      query: {
        search: null
      },
      search: null,
      // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
      xprops: {
        eventbus: new Vue(),
      },
      twilioStatus: null,
      packages: [],
      selectedPackage: null,
      isOpenPackageModal: false,
      processing: false,
      isOpenDetailModal: false,
      isOpenExportUserModal: false,
      userExportOptions: {
        'agencyUsers': true,
        'businessUsers': true,
        'businessSelected': [],
      },
      isOpenExportBusinessModal: false,
      businessExportOptions: {
        'businessSelected': [],
      },
      businesses: [],
      isOpenSendNotificationModal: false,
      userNotificationOptions: {
        'agencyUsers': true,
        'businessUsers': true,
        'businessSelected': [],
        'smsMaxLength': 255
      },
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    isTemplate() {
      return this.user && this.user.agency && this.user.agency.is_template
    },

    canSendNotification() {
      if (this.userNotificationOptions.emailSubject && this.userNotificationOptions.emailContent)
        return true
      return false
    }
  },

  watch: {
    query: {
      handler() {
        this.handleQueryChange()
      },
      deep: true,
    },
  },
  methods: {
    onSendNotification() {
      this.isOpenSendNotificationModal = true
    },

    handleUploadedImage(images) {
      this.userNotificationOptions.smsImages = images
    },

    handleAttachments(files) {
      this.userNotificationOptions.emailAttachments = files
    },

    handleSendNotification() {
      this.processing = true
      const params = {
        agencyUsers: this.userNotificationOptions.agencyUsers,
        businessUsers: this.userNotificationOptions.businessUsers,
        businessIds: this.userNotificationOptions.businessSelected.map(item => item.id).join(','),
        emailSubject: this.userNotificationOptions.emailSubject,
        emailContent: this.userNotificationOptions.emailContent,
      }

      this.$store.dispatch('agency/sendNotification', params).then((res) => {
        this.processing = false
        this.isOpenSendNotificationModal = false
      }).catch(e => {
        this.processing = false
      })
    },

    onExportBusinesses() {
      this.isOpenExportBusinessModal = true
    },

    handleExportBusinesses() {
      this.processing = true
      const params = {
        businessIds: this.businessExportOptions.businessSelected.map(item => item.id).join(',')
      }

      this.$store.dispatch('agency/exportBusinesses', params).then((res) => {
        this.processing = false
        const blob = new Blob([res.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'businesses.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)
        this.isOpenExportBusinessModal = false
      }).catch(e => {
        this.processing = false
      })
    },

    onExportUsers() {
      this.isOpenExportUserModal = true
    },

    handleExportUsers() {
      this.processing = true
      const params = {
        agencyUsers: this.userExportOptions.agencyUsers,
        businessUsers: this.userExportOptions.businessUsers,
        businessIds: this.userExportOptions.businessSelected.map(item => item.id).join(',')
      }

      this.$store.dispatch('agency/exportUsers', params).then((res) => {
        this.processing = false
        const blob = new Blob([res.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'users.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)
        this.isOpenExportUserModal = false
      }).catch(e => {
        this.processing = false
      })
    },

    getAllBusinesses() {
      const param = {
        status: "active",
      };
      this.$store.dispatch('agency/fetchAllBusinesses', param).then((res) => {
        this.businesses = res.data
      }).catch(e => {
      })
    },

    loadPackages() {
      this.$store.dispatch('package/all').then((res) => {
        this.packages = res
      })
    },

    handleAddPackage() {
      this.processing = true
      const params = {
        id: this.selectedBusiness.id,
        package_id: this.selectedPackage.id
      }
      this.$store.dispatch('business/addPackage', params).then((res) => {
        this.handleQueryChange()
        this.processing = false
        this.isOpenPackageModal = false
      }).catch(e => {
        this.processing = false
      })
    },
    handleSearch() {
      this.query.search = this.search
    },

    clearSearch() {
      if (this.query.search === null) {
        this.handleQueryChange()
      } else {
        this.search = null
        this.query.search = null
      }
    },

    sendVerificationEmail(row) {
      this.$store.dispatch('business/sendEmailVerification', row.id).then(({ data }) => {
      })
    },

    verifyEmail(row) {
      this.$store.dispatch('business/verifyEmail', row.id).then(({ data }) => {
        const index = this.data.findIndex(item => item.id === row.id)
        if (index !== -1) {
          this.data[index]['email_verified'] = true
        }
      })
    },

    handleQueryChange() {
      this.data = []
      this.total = 0;
      const handleTotalInstalls = (row) => ({
        ...row,
        count_active_installs: `${row.count_active_installs} / ${row.number_of_installs}`,
      })

      this.loading = true
      const query = { ...this.query, status: 'active' }
      this.$store.dispatch('business/list', query).then(({ data, meta }) => {
        this.data = data.map(handleTotalInstalls)
        this.total = meta.total
        this.loading = false
      })
    },
    handleDeleteBusiness() {
      this.processing = true
      const id = this.modalDeleteBusiness.id
      this.$api.archiveBusiness(id).then(({ data }) => {
        if (data.success) {
          this.closeModalDelete()
          const matchedIndex = this.$store.state.sidebarUserList.filter((data, index) => {
            if (data.id !== id) {
              return index
            }
          })
          this.$store.state.sidebarUserList.splice(matchedIndex, 1)
          this.showToast(data.data.message)
          this.handleQueryChange()
          this.loadInstallStats()
        } else {
          this.showToast(data.data.message)
        }
        this.processing = false
      }).catch(e => {
        this.processing = false
      })
    },

    handleSendPassword() {
      this.loading = true
      const id = this.modalSendPassword.id
      this.closeSendPasswordModal()

      this.$store.dispatch('business/resetPassword', id)
        .then(data => {
          this.loading = false
          if (!data.success) {
            Vue.$toast.open({
              message: data.message,
              type: 'error',
            })
          }
        }).catch(e => {
          this.loading = false
        })
    },

    handleResetUsage() {
      this.loading = true
      const id = this.modalResetUsage.id
      this.closeResetUsageModal()

      this.$store.dispatch('business/resetUsage', id)
        .then(data => {
          this.loading = false
          if (!data.success) {
            Vue.$toast.open({
              message: data.message,
              type: 'error',
            })
          } else {
            this.handleQueryChange()
          }
        }).catch(e => {
          this.loading = false
        })
    },

    getTwilioStatus() {
      this.$store.dispatch('agency/getTwilioStatus', this.user.agency.id)
        .then(data => {
          this.twilioStatus = data.status
        })
        .catch(() => { })
    },

    openModalDelete(business) {
      this.isOpenModalDelete = true
      this.modalDeleteBusiness = business
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
      this.modalDeleteBusiness = {}
    },

    openSendPasswordModal(business) {
      this.isOpenModalSend = true
      this.modalSendPassword = business
    },

    closeSendPasswordModal() {
      this.isOpenModalSend = false
      this.modalSendPassword = {}
    },

    openResetUsageModal(business) {
      this.isOpenModalResetUsage = true
      this.modalResetUsage = business
    },

    openCloneModal(business) {
      this.isOpenCloneModal = true
      this.selectedBusiness = business
    },

    closeCloneModal() {
      this.isOpenCloneModal = false
      this.selectedBusiness = null
    },

    handleCloneModal() {
      this.isLoadingClone = true

      axios
        .request({
          url: `/v1/business/${this.selectedBusiness.id}/clone`,
          method: 'POST',
          data: this.newBusiness
        })
        .then(({ data }) => {
          this.isLoadingClone = false;
          if (data.success) {
            this.showToast('Account cloned!')
            this.handleQueryChange()
            this.closeCloneModal()
          } else {
            this.showToast(data.message, { icon: "fa-exclamation-triangle" });
          }
        }, (error) => {
          this.isLoadingClone = false;
        }).catch((e) => {
          this.isLoadingClone = false;
          console.log("Error", e);
        })
    },

    closeResetUsageModal() {
      this.isOpenModalResetUsage = false
      this.modalResetUsage = {}
    },

    toggleActive(record) {
      this.$store.dispatch('business/toggleActive', record.id)
        .catch(() => { })
    },
    loadInstallStats() {
      axios.request({
        url: `/v1/agencies/${this.user.agency.id}/install-stats`,
        method: 'POST',
      }).then((resp) => {
        this.totalInstalls = resp.data.data.total_installs
        this.availableInstalls = resp.data.data.available_installs
        // if (!this.availableInstalls) {
        //   $(".vuestic-navbar a.le-btn").remove();
        // }
      })
    },

    uniqEmailChk() {
      if (this.newBusiness.email) {
        const params = {
          email: this.newBusiness.email
        }
        return this.$store.dispatch('auth/checkEmail', params)
          .then((res) => {
            return Promise.resolve(true);
          })
          .catch((e) => {
            if (e.response.data && e.response.data.errors) {
              this.$refs.businessForm.setErrors(e.response.data.errors)
            }
            return Promise.resolve(false);
          })
      } else {
        return Promise.resolve(false);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-filter-wrapper {
  @media (max-width: 1230px) {
    flex-direction: column !important;
    align-items: flex-end !important;

    >div {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}

.alert-span {
  font-weight: bold;
  color: $rb-blue;
  display: block;
  text-align: center;
  font-size: 20px;
  padding-bottom: 10px;
}

.agency-notification {
  .btn-sm {
    padding: 5px 10px;
  }
}

.modal-detail {
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 2px 0px;

      span.key {
        font-weight: bold;
        width: 100px;
        display: inline-block;
        text-align: right;
        margin-right: 10px;
      }
    }
  }
}

.business-detail {
  span.key {
    width: 150px !important;
  }
}

div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;

  .tooltip-icon {
    font-size: 16px;
  }
}

.selectedBusinessWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;

  li {
    padding: 3px 10px;
    color: #ffffff;
    background-color: $rb-blue;
    border-radius: 10px;
    margin: 5px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="center">
    <AppDropdownSelect
      :options="moreOptions"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="bottom"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>

import AppDropdownSelect from '../../../common/app-dropdown-select/AppDropdownSelect';

export default {
  components: {
    AppDropdownSelect,
  },

  props: ['row', 'nested', 'xprops'],

  data () {

    return {
      moreOptions: [
        { icon: 'fa fa-info', label: 'Detail', value: 'detail'  },
        { icon: 'fa fa-copy', label: 'Clone', value: 'clone'  },
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit', link: 'businessAccountEdit' },
        { icon: 'fa fa-suitcase', label: 'Add Package', value: 'package'},
        { icon: 'fa fa-question', label: 'Send Password', value: 'send-password'  },
        { icon: 'fa fa-refresh', label: 'Reset Usage', value: 'reset-usage'  },
        { icon: 'fa fa-trash', label: 'Delete', value: 'delete', link: null },
        { icon: 'fa fa-send', label: 'Send Verification Email', value: 'send-verification'  }
      ],
    }
  },

  mounted () {
    if (!this.row.user.email_verified)
    {
      this.moreOptions.push({ icon: 'fa fa-check-circle', label: 'Verify Email', value: 'verify-email'  })
    } else {
      
    }
  },

  methods : {
    onItemClick(item) {
      if (item.value === 'send-password')
      {
        this.xprops.eventbus.$emit('openSendPasswordModal', this.row);
      } else if (item.value === 'verify-email') {
        this.xprops.eventbus.$emit('openVerifyEmail', this.row);
      } else if (item.value === 'send-verification') {
        this.xprops.eventbus.$emit('sendVerificationEmail', this.row);
      } else if (item.value === 'reset-usage') {
        this.xprops.eventbus.$emit('openResetUsageModal', this.row);
      } else if (item.value === 'delete') {
        this.xprops.eventbus.$emit('openDeleteModal', this.row);
      } else if (item.value === 'clone') {
        this.xprops.eventbus.$emit('clone', this.row);
      } else if (item.value === 'package') {
        this.xprops.eventbus.$emit('package', this.row);
      } else if (item.value === 'detail') {
        this.xprops.eventbus.$emit('detail', this.row);
      } else {
        this.$router.push({ name: item.link, params: {id: this.row.id} })
      }
    }
  }
}
</script>
<style lang="scss">
  .more-custom-popover {
    ul li {
      font-size: 17px;
      line-height: 30px;
    }
  }
</style>
<style scoped lang="scss">
  .more-icon {
    display: block;
    padding: 0 5px;
  }
  .custom-popover {
    ul.li {
      font-size: 17px;
      line-height: 30px !important;
    }
  }
</style>
